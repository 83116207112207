<template>
	<div id="pop__onetrust" class="animate" v-if="isTrustVisible">
		<!-- Botão Fechar, que também funciona como "Aceitar" -->
		<button id="close__trust" @click="acceptCookies">
			<Icons name="close" />
		</button>

		<!-- Texto do Popup -->
		<div id="pop__onetrust--text">
			<h2>
				<Icons name="cookie" />
				<span>{{ $t('ontrust.title') }}</span>
			</h2>
			<p>{{ $t('ontrust.copy') }}</p>
		</div>

		<!-- Botões de Aceitar e Rejeitar -->
		<div id="pop__onetrust--content">
			<router-link :to="$t('footer.privacyCookies.url')" class="link--country">
				{{ $t('footer.privacyCookies.label') }}
			</router-link>
			<div id="pop__onetrust--content--btns">
				<AppButton variant="secondary" @click="rejectCookies">
					{{ $t('ontrust.rejeitar') }}
				</AppButton>
				<AppButton variant="primary" @click="acceptCookies">
					{{ $t('ontrust.aceitar') }}
				</AppButton>
			</div>
		</div>
	</div>
</template>
<script>
import Icons from '@/components/Icons.vue';
import AppButton from '@/components/ui/AppButton.vue';

export default {
	components: {
		Icons,
		AppButton,
	},
	data() {
		return {
			isTrustVisible: true, // Controla a visibilidade do popup
		};
	},
	created() {
		// Verifica se já existe uma escolha no sessionStorage
		const cookiesStatus = sessionStorage.getItem('cookiesAccepted');
		if (cookiesStatus !== null) {
			this.isTrustVisible = false; // Esconde o popup se já houver escolha
		}
	},
	methods: {
		acceptCookies() {
			// Salva a escolha no sessionStorage
			sessionStorage.setItem('cookiesAccepted', 'true');
			this.closeTrust();
		},
		rejectCookies() {
			// Salva a escolha no sessionStorage
			sessionStorage.setItem('cookiesAccepted', 'false');

			const cookiesToDelete = [
				'_ga',         // Google Analytics
				'_gid',        // Google Analytics
				'_gat',        // Google Analytics
				'IDE',         // Google Ads
				'__gads',      // Google Ads
				'_fbp',        // Facebook Pixel
				'_fbc',        // Facebook Pixel
				'_tt_enable_cookie', // TikTok Pixel
				'_ttp',        // TikTok Pixel
			];

			cookiesToDelete.forEach(cookieName => {
				document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
				console.log(`Cookie ${cookieName} deletado.`);
			});

			// Limpar o dataLayer do Google Tag Manager
			if (window.dataLayer) {
				window.dataLayer = [];
				console.log('dataLayer redefinido para vazio.');
			}

			// Limpar objetos globais de outros serviços
			if (window.fbq) {
				window.fbq = null;
				console.log('Facebook Pixel desativado.');
			}
			if (window.ttq) {
				window.ttq = null;
				console.log('TikTok Pixel desativado.');
			}
			if (window.gtag) {
				window.gtag = null;
				console.log('Google Ads desativado.');
			}

			this.closeTrust();
		},
		closeTrust() {
			// Fecha o popup
			this.isTrustVisible = false;
		},
	},
};
</script>

<style lang="sass" scoped>
@use '../assets/sass/utilities/_colors' as *
@use '../assets/sass/utilities/_responsive' as *
@use '../assets/sass/utilities/_mixin' as *
@keyframes slideUp 
	0% 
		opacity: 0
		transform: translateY(100%)

	100% 
		opacity: 1
		transform: translateY(0)


#close__trust
	background: none
	position: absolute
	width: 20px
	right: 20px
	top: 20px
	color: var(--lg-mid-gray-200)

	&:hover
		color: var(--lg-red-active)

#pop__onetrust
	background-color: var(--white)
	border-radius: 1.25rem
	padding: 40px
	position: fixed
	height: min-content
	bottom: 2em
	z-index: 3000
	opacity: 0
	width: auto
	inset-inline: 20px
	box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px
	+respond-to($breakpoint-sm-min)
		inset-inline: inherit
		width: min(100%, 700px)
		right: 9rem
		
		

	&.animate
		animation: slideUp 1.5s cubic-bezier(.77,0,.175,1) 1.7s forwards
	

	&--text
		margin-bottom: 10px

		h2
			display: flex
			color: var(--black)
			align-items: center
			gap: 10px
			font-size: clamp(1.1rem, var(--clamp), 1.375rem)
			font-weight: 600
			margin-bottom: 15px
			text-transform: uppercase

			i
				width: 1.25rem

		p
			line-height: 1.2
			font-size: clamp(0.75rem, var(--clamp), 1rem)

	&--content
		display: flex
		justify-content: space-between
		align-items: center
		flex-direction: column
		gap: 1.2rem
		+respond-to($breakpoint-sm-min)
			flex-direction: row
			
		a
			color: var(--lg-red-active)
			font-size: 1rem


		&--btns
			display: flex
			align-items: center
			gap: 12px

			.AppButton
				--p: 10px
			   

</style>