<template>
	<NavCategoryTutos :categoryFilters="categoryFilters" @filter="filterCards" />
	<div class="wrapper">
		<section v-if="show === true" class="tutorial">
			<!-- Video ativo ========================================== -->
			<div class="tutorial--active" v-if="show === true && tutoriais.length > 0">
				<div class="frameVideo">
					<iframe v-if="show" :src="tutoriais[activeIndex]?.externalLink" title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowfullscreen></iframe>
				</div>

				<!-- Video ativo ================inicio========================== -->

				<div class="tutorial--active__description">
					<hgroup>
						<h2>{{ tutoriais[activeIndex]?.content.title }}</h2>
						<span>{{ getDateFormated(tutoriais[activeIndex]?.createdAt) }}</span>
					</hgroup>
					<p>{{ tutoriais[activeIndex]?.content.subTitle }}</p>
					<div class="tagsContent">
						<Tags :tags="tutoriais[activeIndex]?.tags" />
					</div>
				</div>
			</div>
			<!-- Video ativo ========================================== -->

			<!-- lista de videos  ========================================== -->
			<section class="grid--list">
				<div class="grid--list__content">
					<div class="cardVideo" v-for="(tuto, index) in visibleTutos" :key="index"
						@click="setActiveVideo(index, tuto?.id, tuto?.content.title)" :class="{ active: index === activeIndex }">
						<figure>
							<img :src="getImage(tuto.tutorialFiles)" :alt="tuto.content.title"
								:title="tuto.content.title">
						</figure>
						<p>{{ tuto.content.title }}</p>
					</div>


				</div>
				<div class="content--btnMore">
					<button class="loadMoreBtn" v-if="show && tutoriais.length > 8 && totalVisible < tutoriais.length"
						@click="showMore">{{ $t('buttons.showMore') }}
						<Icons name="arrowDown" />
					</button>
				</div>
			</section>
			<!-- lista de videos  ========================================== -->

			<TrendTopcs @activeTrending="activeTrending" :listTrendings="trendings" v-if="show" />

		</section>
	</div>
</template>

<script>
import Icons from '@/components/Icons.vue';
import NavCategoryTutos from '@/components/NavCategoryTutos.vue';
import Tags from '@/components/Tags.vue';
import TrendTopcs from '@/components/TrendTopcs.vue';
import moment from 'moment'

export default {
	components: {
		NavCategoryTutos,
		Tags,
		Icons,
		TrendTopcs
	},
	data() {
		return {
			categoryFilters: [],
			tutoriais: null,
			tags: [],
			activeIndex: 0,
			id: null,
			show: false,
			visibleTutos: [],
			trendings: [],
			videosPerLoad: this.isMobile() ? 2 : 4,
			totalVisible: this.isMobile() ? 4 : 8,
			filters: { limit: 100 },
			monthTranslate: {
				'January': this.$t('months.January'),
				'February': this.$t('months.February'),
				'March': this.$t('months.March'),
				'April': this.$t('months.April'),
				'May': this.$t('months.May'),
				'June': this.$t('months.June'),
				'July': this.$t('months.July'),
				'August': this.$t('months.August'),
				'September': this.$t('months.September'),
				'October': this.$t('months.October'),
				'November': this.$t('months.November'),
				'December': this.$t('months.December')
			}
		}
	},
	created() {
		// SEO ======
		document.title = this.$t('meta.tutoriais.title');
		document.querySelector('meta[name="description"]').setAttribute('content', this.$t('meta.tutoriais.description'));
		document.querySelector('meta[name="keywords"]').setAttribute('content', this.$t('meta.tutoriais.keywords'));

		// Definindo as Meta Tags de Open Graph (og:*)
		document.querySelector('meta[property="og:title"]').setAttribute('content', this.$t('meta.tutoriais.title'));
		document.querySelector('meta[property="og:description"]').setAttribute('content', this.$t('meta.tutoriais.description'));
		// // Definindo as Meta Tags do Twitter
		document.querySelector('meta[name="twitter:title"]').setAttribute('content', this.$t('meta.tutoriais.title'));
		document.querySelector('meta[name="twitter:description"]').setAttribute('content', this.$t('meta.tutoriais.description'));
		// SEO ======
	},
	mounted() {
		if (this.$route.query.id)
			this.id = this.$route.query.id
		this.loadTutoriais()
		this.loadMenus()
	},
	computed: {

	},
	methods: {
		getDateFormated(dt) {
			if (!dt)
				return ''
			moment.locale('en');
			const formattedDate = moment(dt).format('DD.MMMM.YYYY');

			const d = formattedDate.substring(0, formattedDate.indexOf('.') + 1); // Inclui o primeiro ponto
			const m = formattedDate.substring(formattedDate.indexOf('.') + 1, formattedDate.lastIndexOf('.'));
			const y = formattedDate.substring(formattedDate.lastIndexOf('.'));

			const mTranslated = this.monthTranslate[m];

			return `${d}${mTranslated}${y}`
		},
		getSrc(idx) {
			console.log(idx)
		},
		getImage(files) {
			if (!files || files.length <= 0)
				return ''
			let fl = files.find(el => el.tutorialFileTypeId == 1)

			return import.meta.env.VITE_APP_API_URL + 'files/render/' + fl.file.name
		},
		isMobile() {
			return window.innerWidth <= 768;
		},
		setActiveVideo(index, id, value) {
			console.log(id)
			if (index !== this.activeIndex) {
				this.$router.replace({
					path: this.$route.path,
					query: { id: id }
				})
				this.activeIndex = index;
				this.$store.dispatch('route/gaEvent', { element: 'tutorial-selected', content: value, index: id, to: window.location.href })
				.catch(e => {
					console.log(e)
				})
			}
		},
		loadVisibleTutos() {
			this.visibleTutos = this.tutoriais.slice(0, this.totalVisible);
		},
		showMore() {
			this.totalVisible += this.videosPerLoad;
			this.loadVisibleTutos();
		},
		activeTrending(id) {
			this.tutoriais.forEach((c, index) => {
				if (c.id == id) {
					this.setActiveVideo(index, c.id, c.content.title)
					return;
				}
			})
		},
		async getTags() {
			await this.$store.dispatch('tags/get').then((resp) => {
				this.tags = resp
				this.setTutorialTags()
			}).catch(err => {
				console.log('tags/get', err)
			})
		},
		async setTutorialTags() {
			// this.tutoriais.map((tutorial, index) => {
			// 	this.tutoriais[index].tags = tutorial.tagIds.map(tagId => {
			// 		const tagFound = this.tags.find(t => t.id === tagId)
			// 		return tagFound ? tagFound.name : ''
			// 	})
			// })	
			//TODO CONFIGURAR CORRETAMENTE
			return 'a'
		},
		loadTutoriais() {
			this.$store.dispatch('tutoriais/get', this.filters).then((resp, i) => {
				this.tutoriais = resp.data.data.rows
				this.getTags()
				if (this.id) {
					resp.data.data.rows.forEach((c, index) => {
						if (c.id == this.id) {
							this.activeIndex = index
						}
					})
				}
				this.trendings = this.tutoriais.slice(0, 10)
				this.show = true;
				this.loadVisibleTutos();
			})
		},
		loadMenus() {
			this.$store.dispatch('tutoriais/getMenus').then((resp, i) => {
				this.categoryFilters = resp.data.data.rows
			})
		},
		filterCards(id, value) {
			this.$store.dispatch('route/gaEvent', { element: 'manual_tab_click', content: value, index: id, to: window.location.href })
			.catch(e => {
				console.log(e)
			})
			this.filters.tutorialCategoryId = id
			this.loadTutoriais()
		}
	},
	beforeRouteEnter(to, from, next) {
		window.scrollTo(0, 0);
		next();
	}
}
</script>

<style lang="sass" scoped>
@use '../assets/sass/utilities/_colors' as *
@use '../assets/sass/utilities/_responsive' as *
@use '../assets/sass/utilities/_mixin' as *

.wrapper
	padding-bottom: 0

.tutorial
	padding-bottom: 2rem	 
	--pad: 20px

	background-color: var(--white)
	+respond-to($breakpoint-sm-min)
		--pad: 50px

	.tutorial--active

		.frameVideo
			width: 100%
			height: fit-content
			background: var(--black) url('src/assets/img/icon-loading.gif') no-repeat center center / 5%	

			iframe, video
				width: 100%
				display: block
				aspect-ratio: 16 / 9

		&__description
			width: 100%
			max-width: 1000px
			padding-inline: var(--pad)
			margin-bottom: 3rem

			hgroup
				width: fit-content
				padding: 20px 0 10px 0 
				border-bottom: 1px solid var(--black)
				margin-bottom: 10px
			   
				h2
					font-weight: 700
					@include font-size (1.5625rem, 1.875rem)
					margin-bottom: 10px

				span
					font-weight: 600

			p
				font-weight: 600

			.tagsContent
				margin-top: 30px
				display: flex
				flex-wrap: wrap
				gap: 0.375rem


.grid--list
	--sizeCard: 270px

	&__content
		padding: 0  var(--pad)
		display: grid
		grid-template-columns: repeat(auto-fill, minmax(var(--sizeCard), 1fr))
		grid-auto-flow: row
		gap: 1rem



	.cardVideo
		cursor: pointer
		width: 100%

		figure
			aspect-ratio: 16 / 9
			overflow: hidden
			border-radius: 10px
			background-color: var(--black)
			transition: all .23s ease

			img
				transition: all .23s ease
				width: 100%

		p
			margin-top: 5px
			padding-inline: 10px
			line-height: 1.1
			font-weight: 700
			@include line-limited (2)


		&.active, &:hover		
			figure			
				border-bottom: 6px solid var(--lg-red-active)
				img
					opacity: .5
			

</style>